import { Fx } from "@with-cardinal/fx";

export class ImageUpload extends Fx {
	attach() {
		this.fileInput = this.querySelector("input[type='file']");
		this.idInput = this.querySelector("input[data-id-input]");
		this.thumbnailInput = this.querySelector("input[data-thumbnail-input]");
		this.img = this.querySelector("img");
		this.changeButton = this.querySelector("button[data-change-button]");
		this.removeButton = this.querySelector("button[data-remove-button]");

		this.onChange();

		this.listen(this, "dragenter", (evt) => this.setDragging(evt));
		this.listen(this, "dragover", (evt) => this.setDragging(evt));
		this.listen(this, "dragleave", () => this.doneDragging());
		this.listen(this, "dragend", () => this.doneDragging());
		this.listen(this, "drop", (evt) => this.drop(evt));

		this.listen(this.fileInput, "change", () => this.onChange());
		this.listen(this.changeButton, "click", () => this.update());
		this.listen(this.removeButton, "click", () => this.clear());
	}

	setDragging(evt) {
		evt.preventDefault();
		this.dataset.dragging = "true";
	}

	doneDragging() {
		this.dataset.dragging = "false";
	}

	onLoad(e) {
		const img = new Image();
		img.src = e.target.result;
		this.fileInput.value = null;

		this.removeChild(this.img);
		this.appendChild(img);
		this.img = img;

		this.dataset.formReady = "false";
	}

	drop(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		this.dataset.dragging = "false";

		const reader = new FileReader();
		reader.onload = (e) => this.onLoad(e);

		reader.readAsDataURL(evt.dataTransfer.files[0]);
	}

	onChange() {
		if (this.fileInput.files.length > 0) {
			const reader = new FileReader();
			reader.onload = (e) => this.onLoad(e);

			reader.readAsDataURL(this.fileInput.files[0]);
		}
	}

	clear() {
		this.fileInput.value = "";
		this.idInput.value = "";
		this.thumbnailInput.value = "";
		this.dataset.set = "false";
		this.dataset.formReady = "true";

		const newImg = new Image();
		newImg.src = this.dataset.placeholder;
		this.removeChild(this.img);
		this.appendChild(newImg);
		this.img = newImg;
	}

	update() {
		this.fileInput.click();
	}

	async preprocess() {
		const prepared = await this.prepareImage(1800);
		if (prepared) {
			this.idInput.value = prepared.id;
		} else {
			return false;
		}

		const thumbnail = await this.prepareImage(400);
		if (thumbnail) {
			this.thumbnailInput.value = thumbnail.id;
		}

		return true;
	}

	async prepareImage(maxSize) {
		const img = new Image();
		img.src = this.img.src;

		let targetHeight = img.height;
		let targetWidth = img.width;

		if (img.width > img.height) {
			targetWidth = maxSize;
			targetHeight = ((1.0 * maxSize) / img.width) * img.height;
		} else {
			targetHeight = maxSize;
			targetWidth = ((1.0 * maxSize) / img.height) * img.width;
		}

		const canvas = document.createElement("canvas");
		canvas.setAttribute("width", targetWidth);
		canvas.setAttribute("height", targetHeight);

		const ctx = canvas.getContext("2d");
		ctx.fillStyle = "#ffffffff";
		ctx.fillRect(0, 0, canvas.width, canvas.height);
		ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

		const blob = await new Promise((resolve) =>
			canvas.toBlob(resolve, "image/jpeg", 0.7),
		);

		const prepareResult = await fetch(this.dataset.prepare, {
			method: "POST",
			headers: {
				"content-type": "application/json",
			},
			body: JSON.stringify({ _csrf: this.dataset.csrf }),
		});

		if (!prepareResult.ok) {
			throw prepareResult;
		}

		const data = await prepareResult.json();

		const uploadResult = await fetch(data.url, {
			method: "PUT",
			headers: {
				"content-type": "image/jpeg",
			},
			body: blob,
		});

		if (uploadResult.ok) {
			return data;
		}

		return false;
	}
}
