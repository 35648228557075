import { fncx } from "@with-cardinal/fncx";
import { ClientRouter } from "@with-cardinal/client-router";

function onChange(select) {
	const url = new URL(ClientRouter.window.location.href);

	url.searchParams.set("order", select.value);

	ClientRouter.replace(url.toString());
}

function attach(el, listen) {
	const select = el.querySelector("select");

	listen(select, "change", () => onChange(select));
}

export const GardenSearch = fncx(attach);
