import { fncx } from "@with-cardinal/fncx";

function attach(el, listen) {
	const input = el.querySelector("input");
	const wrap = el.querySelector('div[class="text-input"]');

	function onChange() {
		const value = input.value;

		if (value !== "") {
			wrap.dataset.filled = "true";
		} else {
			wrap.dataset.filled = "false";
		}
	}

	onChange();

	listen(input, "input", () => onChange());
}

export const TextInput = fncx(attach);
