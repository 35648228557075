import { Fx } from "@with-cardinal/fx";

export class OverflowMenu extends Fx {
	attach() {
		this.button = this.querySelector("button");
		this.dialog = this.querySelector("dialog");
		this.focusContainer = this.querySelector("div.overflow-menu--container");

		this.listen(this.button, "click", () => this.onButtonClick());
		this.listen(this.dialog, "click", (evt) => this.onDialogClick(evt));
		this.listen(window, "resize", () => this.close());
		this.listen(window, "scroll", () => this.close());
	}

	open() {
		this.dialog.showModal();
		this.positionDialog();
	}

	close() {
		if (this.dialog.open) {
			this.dialog.close();
		}
	}

	positionDialog() {
		const { top, right, height } = this.button.getBoundingClientRect();
		const { width: dialogWidth, height: dialogHeight } =
			this.dialog.getBoundingClientRect();

		if (top + height + dialogHeight + 16 >= window.innerHeight) {
			this.dialog.style.top = `${top - dialogHeight}px`;
		} else {
			this.dialog.style.top = `${top + height}px`;
		}

		this.dialog.style.left = `${right - dialogWidth}px`;
	}

	onButtonClick() {
		if (this.dialog.open) {
			this.close();
		} else {
			this.open();
		}
	}

	onDialogClick(evt) {
		if (evt.target === this.dialog) {
			this.close();
		}
	}
}
