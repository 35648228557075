import { fncx } from "@with-cardinal/fncx";

function typeFieldChanged(evt, hideZone) {
	const gardenType = evt.target.value;

	if (gardenType === "outdoor") {
		hideZone.dataset.hidden = "false";
	} else {
		hideZone.dataset.hidden = "true";
	}
}

export const GardenHideZone = fncx((elem, listen) => {
	const typeField = elem.closest("form").querySelector("[name='gardenType'");

	listen(typeField, "change", (evt) => typeFieldChanged(evt, elem));
});
