import { Fx } from "@with-cardinal/fx";

export class PlausibleEvent extends Fx {
	attach() {
		if (this.getAttribute("name")) {
			const props = {};

			for (const name of this.getAttributeNames()) {
				props[name] = this.getAttribute(name);
			}

			window.plausible(this.getAttribute("name"), { props });
		}
	}
}
