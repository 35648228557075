// Define a new function component. Takes an component definition and returns a
// detach function.
export function fncx(defn) {
	const component = class extends FncxComponent {};
	component.attachFn = defn;

	return component;
}

class FncxComponent extends HTMLElement {
	events = [];

	listen(elem, type, fn) {
		this.events.push([elem, type, fn]);
		elem.addEventListener(type, fn);
	}

	connectedCallback() {
		if (this.isConnected) {
			const listenHere = (elem, type, fn) => this.listen(elem, type, fn);
			this.detach = this.constructor.attachFn(this, listenHere);
		}
	}

	disconnectedCallback() {
		if (this.detach) {
			this.detach();
		}

		for (const event of this.events) {
			event[0].removeEventListener(event[1], event[2]);
		}
	}
}
