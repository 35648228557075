import { Fx } from "@with-cardinal/fx";

export class ShowPlantPage extends Fx {
	attach() {
		if (window.location.search.includes("tapped")) {
			const url = new URL(window.location.href);

			url.searchParams.delete("tapped");

			window.history.replaceState(
				{
					scrollPos: [window.scrollX, window.scrollY],
				},
				"",
				url.href,
			);
		}
	}
}
