import { Fx } from "@with-cardinal/fx";

export class JournalFormImages extends Fx {
	attach() {
		this.addButton = this.querySelector("button.journal-form-images--add");
		this.fileInput = this.querySelector("input[type=file]");

		this.closeSvg = this.querySelector("template#delete-svg");

		this.listen(this.addButton, "click", () => {
			this.fileInput.click();
		});

		this.listen(this.fileInput, "change", () => {
			this.addImage();
		});

		for (const deleteButton of this.querySelectorAll(
			".journal-form-images--delete",
		)) {
			this.listen(deleteButton, "click", () => {
				deleteButton.closest(".journal-form-images--image").remove();
			});
		}
	}

	addImage() {
		if (this.fileInput.files.length > 0) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const img = new Image();
				img.src = e.target.result;
				this.fileInput.value = null;

				const div = document.createElement("div");
				div.classList.add("journal-form-images--image");
				div.appendChild(img);

				const spinner = document.createElement("div");
				spinner.classList.add("spinner");
				spinner.dataset.size = "large";
				spinner.dataset.light = "true";
				const innerDiv = document.createElement("div");
				innerDiv.appendChild(document.createElement("div"));
				spinner.appendChild(innerDiv);
				div.appendChild(spinner);

				const deleteButton = document.createElement("button");
				deleteButton.type = "button";
				deleteButton.classList.add("journal-form-images--delete");
				deleteButton.appendChild(this.closeSvg.content.cloneNode(true));

				this.listen(deleteButton, "click", () => {
					div.remove();
				});

				div.appendChild(deleteButton);

				this.insertBefore(div, this.fileInput);
			};

			reader.readAsDataURL(this.fileInput.files[0]);
		}
	}

	async preprocess() {
		this.dataset.preprocessing = true;

		const containers = this.querySelectorAll(".journal-form-images--image");
		for (const div of containers) {
			div.dataset.processing = true;
			const sourceImg = div.querySelector("img");

			const img = new Image();
			img.src = sourceImg.src;

			const hidden = div.querySelector("input[type=hidden]");
			if (!hidden) {
				const data = await this.processImage(img, 1920);
				const input = document.createElement("input");
				input.type = "hidden";
				input.name = "images";
				input.value = data.id;
				div.appendChild(input);

				const thumbData = await this.processImage(img, 800);
				const thumbInput = document.createElement("input");
				thumbInput.type = "hidden";
				thumbInput.name = "thumbnails";
				thumbInput.value = thumbData.id;
				div.appendChild(thumbInput);
			}

			div.dataset.processing = false;
		}

		return true;
	}

	async processImage(img, size) {
		let scaleWidth = false;
		if (img.width > img.height) {
			scaleWidth = true;
		}

		const canvas = document.createElement("canvas");
		canvas.setAttribute(
			"width",
			scaleWidth ? size : ((1.0 * size) / img.height) * img.width,
		);
		canvas.setAttribute(
			"height",
			scaleWidth ? ((1.0 * size) / img.width) * img.height : size,
		);

		const ctx = canvas.getContext("2d");
		ctx.fillStyle = "#ffffffff";
		ctx.fillRect(0, 0, canvas.width, canvas.height);
		ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

		const blob = await new Promise((resolve) =>
			canvas.toBlob(resolve, "image/jpeg", 0.7),
		);

		const prepareResult = await fetch(this.dataset.prepare, {
			method: "POST",
			headers: {
				"content-type": "application/json",
			},
			body: JSON.stringify({ _csrf: this.dataset.csrf }),
		});

		if (!prepareResult.ok) {
			throw prepareResult;
		}

		const data = await prepareResult.json();

		const uploadResult = await fetch(data.url, {
			method: "PUT",
			headers: {
				"content-type": "image/jpeg",
			},
			body: blob,
		});

		if (!uploadResult.ok) {
			throw uploadResult;
		}

		return data;
	}
}
