import { Fx } from "@with-cardinal/fx";

export class ImageZoom extends Fx {
	attach() {
		this.dialog = this.querySelector("dialog");

		this.listen(this, "click", () => this.zoom());
		this.listen(this.dialog, "click", (evt) => {
			evt.stopPropagation();
			evt.preventDefault();

			this.dialog.close();
		});
	}

	zoom() {
		this.dialog.showModal();
	}
}
