import { fncx } from "@with-cardinal/fncx";

export const ClipboardInput = fncx((elem, listen) => {
	const button = elem.querySelector("button");
	const input = elem.querySelector("input");

	listen(button, "click", () => {
		navigator.clipboard.writeText(input.value);
		elem.dataset.copied = "";

		setTimeout(() => delete elem.dataset.copied, 1500);
	});
});
