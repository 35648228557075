import { Fx } from "@with-cardinal/fx";

export class MarketingHeader extends Fx {
	attach() {
		this.menuButton = this.querySelector("button");

		if (this.menuButton) {
			this.listen(this.menuButton, "click", () => this.onMenuClick());
		}

		this.listen(document, "scroll", () => this.onScroll());
	}

	onMenuClick() {
		document.body.classList.toggle("mobile-menu-open");
	}

	onScroll() {
		const prev = this.dataset.scrolled;

		if (window.scrollY > 0 && !prev) {
			this.dataset.scrolled = true;
		} else if (window.scrollY === 0 && prev) {
			delete this.dataset.scrolled;
		}
	}
}
