import { fncx } from "@with-cardinal/fncx";

function attach(el, listen) {
	const input = el.querySelector("input");
	const clearButton = el.querySelector("button");

	function onChange() {
		const value = input.value;

		if (value.trim() !== "") {
			el.dataset.filled = "true";
		} else {
			el.dataset.filled = "false";
		}
	}

	onChange();

	listen(input, "input", () => onChange());
	listen(clearButton, "click", () => {
		input.value = "";
		onChange();
	});
}

export const DateInput = fncx(attach);
