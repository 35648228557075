import { Fx } from "@with-cardinal/fx";

export class SelectInput extends Fx {
	input;
	isEmpty;

	attach() {
		this.input = this.querySelector("select");
		this.onChange();

		this.listen(this.input, "change", () => this.onChange());
	}

	onChange() {
		this.isEmpty = this.input.value === "";

		this.input.dataset.unset = this.isEmpty ? "true" : "false";
	}
}
