import { Fx } from "@with-cardinal/fx";

export class TextareaInput extends Fx {
	input;
	opts;

	attach() {
		this.input = this.querySelector("textarea");

		this.onChange();

		this.listen(this.input, "input", () => this.onChange());
	}

	onChange() {
		const value = this.input.value;

		if (value.trim() !== "") {
			this.dataset.filled = "true";
		} else {
			this.dataset.filled = "false";
		}
	}
}
