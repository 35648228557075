import { Fx } from "@with-cardinal/fx";

export class MobileMenu extends Fx {
	attach() {
		this.button = this.querySelector("button.mobile-menu--close");

		this.listen(this.button, "click", () => this.close());

		const links = this.querySelectorAll("a");
		for (const link of links) {
			this.listen(link, "click", () => this.linkClick());
		}
	}

	close() {
		document.body.classList.remove("mobile-menu-open");
	}

	linkClick() {
		this.close();
	}
}
