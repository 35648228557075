import { fncx } from "@with-cardinal/fncx";
import { debounce } from "../../misc/debounce.js";

function attach(el, listen) {
	const input = el.querySelector("textarea");
	const wrap = el.querySelector('div[class="text-complete"]');
	const menu = el.querySelector('div[class="text-complete--menu"]');

	onChange(input, wrap, menu);

	listen(input, "input", () => onChange(input, wrap, menu));
	listen(input, "keydown", (evt) => onKeyPress(evt, menu, input));
	listen(input, "blur", () =>
		setTimeout(() => updateOptions(input, menu), 100),
	);

	updatePosition(input, menu);
	listen(document, "scroll", () => updatePosition(input, menu));
	listen(window, "resize", () => updatePosition(input, menu));

	// handle tab focus
	let tabHit = false;
	listen(window, "keydown", (evt) => {
		if (evt.keyCode === 9) {
			tabHit = true;
		}
	});
	listen(window, "keyup", () => {
		tabHit = false;
	});

	listen(input, "focus", (evt) => {
		evt.preventDefault();

		if (tabHit) {
			setTimeout(
				() => input.setSelectionRange(input.value.length, input.value.length),
				0,
			);
		}
	});
}

export const TextComplete = fncx(attach);

function onChange(input, wrap, menu) {
	const value = input.value.replace(/\n/g, "");

	if (input.value !== value) {
		input.value = value;
	}

	if (value !== "") {
		wrap.dataset.filled = "true";
	} else {
		wrap.dataset.filled = "false";
	}

	updateOptions(input, menu);
}

async function updateOptionsInternal(input, menu) {
	const options = await loadOptions(
		input.value,
		input === document.activeElement,
	);

	clearOptions(menu);
	updatePosition(input, menu);

	if (!options) {
		return;
	}

	for (const option of options) {
		const div = document.createElement("div");
		div.classList.add("text-complete--menu-item");
		div.textContent = option.value;

		div.addEventListener("click", () => {
			if (input.value !== option.value) {
				input.value = option.value;
			}

			setTimeout(() => clearOptions(menu), 0);
		});

		menu.appendChild(div);
	}
}
const updateOptions = debounce(updateOptionsInternal, 300);

function clearOptions(menu) {
	while (menu.firstChild) {
		menu.removeChild(menu.firstChild);
	}
}

async function loadOptions(value, focus) {
	if (value !== "" && focus) {
		const resp = await fetch(`/api/lookup?scientificName=${value}`, {
			redirect: "manual",
		});

		if (!resp.ok) {
			return [];
		}

		return await resp.json();
	}
}

function onKeyPress(evt, menu, input) {
	if (evt.key !== "ArrowUp" && evt.key !== "ArrowDown" && evt.key !== "Enter") {
		return;
	}

	const items = menu.children;
	if (items.length === 0) {
		return;
	}

	const selected = menu.querySelector('[data-selected="true"]');
	const selectedIndex = Array.from(items).indexOf(selected);
	let newIndex;

	if (evt.key === "ArrowUp") {
		evt.preventDefault();
		if (selectedIndex === -1 || selectedIndex === 0) {
			newIndex = items.length - 1;
		} else {
			newIndex = selectedIndex - 1;
		}
	} else if (evt.key === "ArrowDown") {
		evt.preventDefault();
		if (selectedIndex === -1 || selectedIndex === items.length - 1) {
			newIndex = 0;
		} else {
			newIndex = selectedIndex + 1;
		}
	} else if (evt.key === "Enter") {
		evt.preventDefault();
		if (selectedIndex !== -1) {
			items[selectedIndex].click();
			return;
		}
	}

	if (newIndex !== undefined) {
		if (selectedIndex !== -1) {
			selected.dataset.selected = false;
		}

		items[newIndex].dataset.selected = true;
		input.value = items[newIndex].textContent;
		items[newIndex].scrollIntoView({ block: "nearest" });
	}
}

function updatePosition(input, menu) {
	const inputRect = input.getBoundingClientRect();
	const docHeight = window.visualViewport.height;

	if (inputRect.top > (docHeight - input.style.height) / 2) {
		menu.dataset.position = "above";
		menu.style.maxHeight = `${Math.floor((inputRect.top - 16) / 56) * 56}px`;
	} else {
		menu.dataset.position = "below";
		menu.style.maxHeight = `${Math.floor((docHeight - inputRect.bottom - 16) / 56) * 56}px`;
	}
}
