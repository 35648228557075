import { fncx } from "@with-cardinal/fncx";

export const ReferenceForm = fncx((elem, listen) => {
	const urlInput = elem.querySelector("input[name='url']");
	const publicationInput = elem.querySelector("input[name='publication']");
	const textarea = elem.querySelector("textarea[name='content']");
	const titleInput = elem.querySelector("input[name='title']");

	listen(urlInput, "change", () =>
		loadPublication(urlInput.value, publicationInput),
	);

	listen(textarea, "paste", (evt) => {
		const text = evt.clipboardData.getData("text/plain").trim();

		if (!text.startsWith("<html")) {
			const lines = text.split("\n");
			urlInput.value = lines[0];
			titleInput.value = lines[1];
			textarea.value = lines.slice(2).join("\n");
			loadPublication(urlInput.value, publicationInput);
		}
	});
});

async function loadPublication(url, publicationInput) {
	const response = await fetch(`/api/publication?url=${url}`);
	const data = await response.json();

	publicationInput.value = data.value;
}
