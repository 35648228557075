import { ClientRouter } from "@with-cardinal/client-router";
import { fncx } from "@with-cardinal/fncx";

function setLabel(elem, label) {
	const checked = elem.querySelector("input:checked");
	label.innerText = checked.dataset.title;

	if (checked.dataset.slug) {
		elem.dataset.sku = checked.dataset.slug;
	}
}

function onChange(elem, label) {
	setLabel(elem, label);

	const checked = elem.querySelector("input:checked");
	if (checked.dataset.slug) {
		ClientRouter.push(
			`${ClientRouter.window.location.pathname}#${checked.dataset.slug}`,
		);
	}

	const evt = new CustomEvent("change", {
		detail: { sku: checked.dataset.slug },
	});
	elem.dispatchEvent(evt);
}

export const ColorSelect = fncx((elem, listen) => {
	const inputs = elem.querySelectorAll("input");
	const label = elem.querySelector("label");

	if (window.location.hash) {
		const hash = window.location.hash.slice(1);
		const checked = elem.querySelector(`input[data-slug="${hash}"]`);

		if (checked) {
			checked.checked = true;
		}
	}

	setLabel(elem, label);

	for (const input of inputs) {
		listen(input, "change", (evt) => {
			evt.stopPropagation();
			onChange(elem, label);
		});
	}
});
