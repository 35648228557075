import { Fx } from "@with-cardinal/fx";

export class RadioCard extends Fx {
	radio;

	attach() {
		this.radio = this.querySelector("input");

		this.onChange();

		this.listen(this.radio, "change", () => {
			for (const card of document.querySelectorAll("radio-card")) {
				card.onChange();
			}
		});
	}

	onChange() {
		const checked = this.radio.checked;

		if (checked) {
			this.dataset.checked = "true";
		} else {
			this.dataset.checked = "false";
		}
	}
}
