import { Fx } from "@with-cardinal/fx";

export class TagExperiment extends Fx {
	attach() {
		window.plausible("experiment", {
			props: {
				experimentName: this.getAttribute("name"),
				experimentVariation: this.getAttribute("variation"),
			},
		});
	}
}
